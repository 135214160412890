<template>
  <div class="social-sharing">
    <div ref="dropdown" class="btn-share" @click="dropdownVisible = !dropdownVisible">
      <icon-share class="icon" />
      <span>Поделиться</span>
    </div>
    <transition name="dropdown">
      <div v-if="dropdownVisible" v-click-outside="hideDropdown" class="sharing-dropdown">
        <div
          v-for="item in listSocials"
          :key="item.name"
          class="sharing-link"
        >
          <share-network
            :network="item.name"
            :url="url"
            :title="shareObject.title"
            :description="shareObject.description"
            class="link item"
          >
            <component :is="'icon-' + item.name" class="icon" />
            <span>{{ item.title }}</span>
          </share-network>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconVk from '@/assets/img/icons/vk.svg'
import IconFacebook from '@/assets/img/icons/facebook.svg'
import IconShare from '@/assets/img/icons/share.svg'

export default {
  name: 'SocialSharingDropdown',
  components: {
    IconVk,
    IconFacebook,
    IconShare
  },
  props: {
    shareObject: {
      type: Object,
      required: true
    },
    urlPath: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dropdownVisible: false,
      listSocials: [
        {
          name: 'vk',
          title: 'ВКонтакте'
        },
      ]
    }
  },
  computed: {
    url () {
      return window.location.origin + this.urlPath
    }
  },
  methods: {
    hideDropdown () {
      this.dropdownVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .dropdown-enter-active, .dropdown-leave-active {
    transition: all .3s;
  }
  .dropdown-leave-to, .dropdown-enter {
    opacity: 0 !important;
  }
  .social-sharing {
    z-index: 2;
    position: relative;
    .btn-share {
      cursor: pointer;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 8px;
      }
    }
    .sharing-dropdown {
      right: 0;
      max-width: 420px;
      overflow: hidden;
      position: absolute;
      background: $white;
      border-radius: $border-radius-base;
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.02)) drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.08));
      @include max-w-xs {
        max-width: 200px;
      }
      .sharing-link {
        transition: 0.3s;
        padding: 12px 16px;
        @include max-w-lg {
          padding: 10px 14px;
        }
        @include max-w-xs {
          padding: 8px 12px;
        }
        &:hover {
          background: rgba(#000, .1);
        }
        .link {
          display: flex;
          align-items: center;
          .icon {
            margin-right: 6px;
            @include max-w-xs {
              width: 18px;
              height: 18px;
            }
            path {
              fill: $dark;
              stroke: $dark;
            }
          }
        }
      }
    }
  }
</style>