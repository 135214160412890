<template>
  <article class="article-item">
    <div class="wrapper-img">
      <img :src="require(`@/assets/img/articles/${article.img}`)">
    </div>
    <div class="content">
      <div class="info">
        <div class="time mb-xs">
          <icon-clock class="icon-clock" />
          <span>Время: {{ article.time }}</span>
        </div>
        <social-sharing-button :share-object="article" :url-path="`/articles/_url`" />
      </div>
      <h2 class="title fs--medium fw--bold mb-xs">
        {{ article.title }}
      </h2>
      <p class="description fs--small mb-xs">
        {{ article.description }}
      </p>
      <app-link to="/articles/_url">
        Читать далее
      </app-link>
    </div>
  </article>
</template>

<script>
import AppLink from '@/components/common/AppLink'
import IconClock from '@/assets/img/icons/clock.svg'
import SocialSharingButton from '@/components/SocialSharingButton'

export default {
  name: 'ArticleItem',
  components: {
    AppLink,
    IconClock,
    SocialSharingButton
  },
  props: {
    article: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .article-item {
    position: relative;
    padding-left: 36px;
    padding-top: 36px;
    @include max-w-lg {
      padding-left: 8px;
      padding-top: 8px;
    }
    .wrapper-img {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 176px;
      height: 128px;
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.02)) drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.08));
      border-radius: $border-radius-base;
      overflow: hidden;
      @include max-w-lg {
        width: 140px;
        height: 100px;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .content {
      width: 304px;
      padding: 26px 16px;
      background: linear-gradient(180deg, rgba(250, 250, 250, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%);
      border-radius: 16px;
      @include max-w-lg {
        width: 280px;
      }
      .info {
        padding-left: 150px;
        margin-bottom: 34px;
        @include max-w-lg {
          margin-bottom: 28px;
          padding-left: 130px;
        }
        .time, .share {
          display: flex;
          align-items: center;
          .icon-clock {
            margin-right: 8px;
            width: 15px;
            height: 14px;
            path {
              fill: $dark;
              stroke: $dark;
            }
          }
        }
      }
      .description {
        font-weight: 300;;
      }
    }
  }
</style>